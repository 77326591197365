<template>
  <div>
    <b-container>
      <b-row class="mb-3 account-settings">
        <b-col offset-md="2" md="4">
          <form @submit.prevent="updateUser">
            <div class="form-group">
              <label for="name">Neuer Name:</label>
              <b-form-input
                type="name"
                id="name"
                v-model="newUsername"
                required
              ></b-form-input>
              <label for="email">Neue Email:</label>
              <b-form-input
                type="email"
                id="email"
                v-model="newEmail"
                required
              ></b-form-input>
            </div>
            <div class="form-group">
              <label for="password">Neues Passwort:</label>
              <b-form-input
                type="password"
                id="password"
                v-model="newPassword"
                required
              ></b-form-input>
            </div>
            <button type="submit" class="btn btn-primary">Aktualizieren</button>
            <button @click="redirectToHome()" class="btn btn-danger ml-2">
              Schliessen
            </button>
          </form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
@media screen and (max-width: 485px) {
  .account-settings {
    margin-top: 200px;
  }
}
</style>

<script>
import axios from "axios";
export default {
  name: "AccountSettingsPage",
  data() {
    return {
      newUsername: window.localStorage.getItem("authedUsername"),
      newEmail: window.localStorage.getItem("authedEmail"),
      newPassword: "",
    };
  },
  computed: {
    userAuthedId() {
      return this.$store.state.userAuthedId;
    },
  },
  methods: {
    async updateUser() {
      const token = localStorage.getItem("jwt");

      try {
        const response = await axios.put(
          `${process.env.VUE_APP_URL}/users/${this.userAuthedId}`,
          {
            username: this.newUsername,
            email: this.newEmail,
            password: this.newPassword,
            confirmed: true,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          // Check the response status code
          localStorage.clear();
          this.$store.commit("SET_USER_AUTHED", "");
          alert("Benutzer wurde erfolgreich aktualisiert.");
          this.newEmail = "";
          this.newPassword = "";
          this.$router.push("/");
        } else {
          alert("Error: " + response.data.message);
        }
      } catch (error) {
        console.error("Error:", error);
        alert(
          "Ein Fehler ist aufgetreten, während der Benutzer aktualisiert wurde."
        );
      }
    },
    redirectToHome() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>
