<template>
  <b-modal id="employeeRemarksDialog" hide-footer hide-header>
    <div v-if="filteredEmployee">
      <p class="border-bottom pb-2">
        <b> {{ filteredEmployee.name }} {{ filteredEmployee.lastname }} </b>
      </p>
      <p class="employee-remark">
        <BIconChatLeftDotsFill class="mr-2" />
        {{ filteredEmployee.remark }}
      </p>
    </div>
  </b-modal>
</template>

<style lang="scss" scoped>
.employee-remark {
  white-space: pre-line;
}
</style>

<script>
import { mapState } from "vuex";

export default {
  name: "EmployeeRemarksDialog",
  computed: {
    ...mapState(["employees", "selected_employee"]),
    filteredEmployee() {
      return this.employees.find(
        (employee) => employee.id === this.selected_employee
      );
    },
  },
};
</script>
