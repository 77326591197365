import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import EmployeeEditPage from "../views/EmployeeEditPage.vue";
import AddNewStationPage from "../views/AddNewStationPage.vue";
import StationEditPage from "../views/StationEditPage.vue";
import AddNewEmployeePage from "../views/AddNewEmployeePage.vue";
import AccountSettingsPage from "../views/AccountSettingsPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/edit-candidate/:id",
    name: "EmployeeEditPage",
    component: EmployeeEditPage,
    props: true,
  },
  {
    path: "/add-company",
    name: "AddNewStationPage",
    component: AddNewStationPage,
  },
  {
    path: "/edit-company/:id",
    name: "StationEditPage",
    component: StationEditPage,
    props: true,
  },
  {
    path: "/add-candidate",
    name: "AddNewEmployeePage",
    component: AddNewEmployeePage,
  },
  {
    path: "/account-settings",
    name: "AccountSettingsPage",
    component: AccountSettingsPage,
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  let isUserAuthed = window.localStorage.getItem("jwt");
  if (to.name == "Home" && !isUserAuthed) next({ name: "Login" });
  if (to.name == "AccountSettingsPage" && !isUserAuthed)
    next({ name: "Login" });
  if (to.name == "Login" && isUserAuthed) next({ name: "Home" });
  else next();
});

export default router;
