<template>
  <div>
    <b-container fluid>
      <b-row class="menu fixed-top">
        <b-container>
          <b-row>
            <!-- Logo -->
            <b-col @click="redirectToHome()" md="3">
              <img
                class="logo"
                src="../assets/logo.svg"
                @click="scrollToTop()"
              />
            </b-col>
            <!-- Search -->
            <b-col
              v-if="userIsAuthed"
              class="search-input"
              offset-md="4"
              offset-sm="1"
              cols="8"
              md="3"
            >
              <b-form-input
                v-model="searchInput"
                type="text"
                placeholder="Suchen..."
              />
            </b-col>
            <!-- Account settings -->
            <b-col
              v-if="userIsAuthed"
              @click="accSettings()"
              class="account-settings"
              offset-md="1"
              cols="1"
              md="1"
            >
              <BIconGear />
            </b-col>
            <!-- Logout -->
            <b-col
              v-if="userIsAuthed"
              @click="logOut()"
              class="logout"
              cols="1"
              md="1"
            >
              <BIconPower />
            </b-col>
          </b-row>
        </b-container>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
.menu {
  background: #333;
  padding: 8px 0px 12px 0px;
}
.logo {
  cursor: pointer;
  max-width: 34px;
}
.logout {
  cursor: pointer;
  max-width: 45px;
  margin-top: 8px;
  svg {
    color: white;
  }
}

.account-settings {
  @extend .logout;
}

@media screen and (max-width: 485px) {
  .logo {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
  }
  .search-input {
    margin-left: 10px;
    margin-bottom: 10px;
  }
}
</style>

<script>
export default {
  name: "TheHeader",
  data() {
    return {
      searchInput: "",
    };
  },
  watch: {
    searchInput(newVal) {
      this.$store.commit("SET_SEARCH_INPUT", newVal);
    },
  },
  computed: {
    userIsAuthed() {
      return this.$store.state.userIsAuthed;
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    logOut() {
      localStorage.removeItem("jwt");
      this.$store.commit("SET_USER_AUTHED", "");
      this.$router.push("/login");
    },
    accSettings() {
      this.$router.push("/account-settings");
    },
    redirectToHome() {
      const isHomePage = this.$route.name === "Home";
      this.$store.commit("SET_SEARCH_INPUT", "");
      if (!isHomePage) this.$router.push({ name: "Home" });
    },
  },
};
</script>
