<template>
  <b-modal
    id="employee-dialog"
    :title="title"
    @ok="selectEmployee"
    @hidden="resetSelection"
    ok-title="✓"
    cancel-title="X"
  >
    <b-form>
      <b-form-group label="Kandidat Wählen" label-for="employee-select">
        <v-select
          id="employee-select"
          v-model="selectedEmployee"
          :options="employeeOptions"
          :closeOnSelect="false"
          multiple
          label="text"
          placeholder="Wählen Sie einen oder mehrere Kandidaten aus"
        />
      </b-form-group>
      <b-button @click="openAddNewEmployeePage()" variant="primary"
        ><BIconPersonPlusFill
      /></b-button>
    </b-form>
  </b-modal>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "EmployeeDialog",
  components: {
    vSelect,
  },
  computed: {
    ...mapState(["employees", "stations", "userAuthedId"]),
    title() {
      return "Kandidat zur Firma hinzufügen";
    },
    selected_station() {
      return this.$store.state.selected_station;
    },
    employeeOptions() {
      // Array of all employee IDs that are assigned to a station
      const assignedEmployeeIds = this.stations.reduce((acc, station) => {
        return [...acc, ...station.employees.map((employee) => employee.id)];
      }, []);

      // Filter the employees array to only include employees not assigned to any station
      const unassignedEmployees = this.employees.filter(
        (employee) =>
          !assignedEmployeeIds.includes(employee.id) &&
          employee.user.id == this.userAuthedId
      );

      // Return the employee options
      return unassignedEmployees.map((employee) => ({
        value: employee.id,
        text: `${employee.name} ${employee.lastname}`,
      }));
    },
  },
  data() {
    return {
      selectedEmployee: [],
    };
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("employee-dialog");
    },
    showModal() {
      this.$bvModal.show("employee-dialog");
    },
    async selectEmployee() {
      this.$emit("select-employee", this.selectedEmployee);

      const selectedStation = this.$store.state.stations.find(
        (station) => station.id === this.selected_station
      );

      if (selectedStation) {
        const updatedStation = {
          ...selectedStation,
          employees: [
            ...selectedStation.employees,
            ...this.selectedEmployee.map((employee) => employee.value),
          ],
        };

        const token = localStorage.getItem("jwt");

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        try {
          const response = await axios.put(
            `${process.env.VUE_APP_URL}/stations/${this.selected_station}`,
            updatedStation,
            config
          );
          this.$store.dispatch("updateStation", response.data);
        } catch (error) {
          console.error(
            "There was an error adding the employees to the station",
            error
          );
        }
      }

      this.hideModal();
    },

    resetSelection() {
      this.selectedEmployee = [];
    },
    openAddNewEmployeePage() {
      this.$router.push({ name: "AddNewEmployeePage" });
    },
  },
};
</script>

<style src="vue-select/dist/vue-select.css"></style>
