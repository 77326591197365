<template>
  <div>
    <transition-group tag="b-row" name="list">
      <b-col
        @click="relocateEmployee(station.id)"
        v-for="station in filteredStations"
        :key="station.id"
        md="4"
      >
        <div class="station-wrapper">
          <ul class="list-inline station-info">
            <li
              @click="openStationInfoDialog(station.id)"
              class="list-inline-item"
            >
              <h5>
                {{ station.name }}
              </h5>
            </li>
            <li
              class="list-inline-item add-employee"
              @click.stop="openDialog(station.id)"
            >
              <BIconPersonPlusFill />
            </li>
          </ul>
          <!-- Employees -->
          <EmployeesList
            :stationemployees="station.employees"
            :stationId="station.id"
          />
        </div>
      </b-col>
    </transition-group>

    <!-- Dotted divider line -->
    <b-row>
      <b-col md="12">
        <div class="divider"></div>
      </b-col>
    </b-row>

    <!-- Add new station -->
    <b-row>
      <b-col
        @click="navigateToAddNewStationPage()"
        class="add-new-station-btn"
        md="4"
      >
        <div class="station-wrapper pb-1">
          <h5><BIconPlus /> Neue Firma hinzufügen</h5>
        </div>
      </b-col>
    </b-row>

    <!-- Modals -->
    <EmployeeRemarksDialog />
    <EmployeeDialog ref="employeeDialog" :employees="employees" />
    <StationInfoDialog ref="stationInfoDialog" />
    <DeleteStationDialog />
  </div>
</template>

<style lang="scss" scoped>
.add-new-station-btn {
  cursor: pointer;
}
.station-wrapper {
  padding-top: 10px;
  border-radius: 10px;
  background: #dedede;
  margin-bottom: 50px;
  box-shadow: 3px 5px 5px #949494;
  h5 {
    color: #555;
    margin-left: 22px;
  }
}
.station-info {
  li:first-child {
    cursor: pointer;
  }
  position: relative;
  margin-bottom: 3px;
  .add-employee {
    cursor: pointer;
    position: absolute;
    right: 7px;
    bottom: 5px;
  }
}
.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.divider {
  border-bottom: 1px dotted lightgrey;
  margin-bottom: 30px;
}
</style>

<script>
import axios from "axios";
import { mapState } from "vuex";
import EmployeesList from "../components/EmployeesList.vue";
import EmployeeRemarksDialog from "../components/EmployeeRemarksDialog.vue";
import EmployeeDialog from "../components/EmployeeDialog.vue";
import StationInfoDialog from "../components/StationInfoDialog.vue";
import DeleteStationDialog from "../components/DeleteStationDialog.vue";
export default {
  name: "StationsList",
  components: {
    EmployeesList,
    EmployeeRemarksDialog,
    EmployeeDialog,
    StationInfoDialog,
    DeleteStationDialog,
  },
  computed: {
    ...mapState([
      "stations",
      "employees",
      "search_input",
      "selected_employee",
      "userAuthedId",
    ]),
    filteredStations() {
      const searchInput = this.search_input.toLowerCase();
      const isMatch = (str) => str?.toLowerCase().includes(searchInput);
      return this.stations.filter(
        (station) =>
          (isMatch(station.name) ||
            station.employees?.some(
              (employee) => isMatch(employee.name) || isMatch(employee.lastname)
            )) &&
          station.user.id == this.userAuthedId
      );
    },
  },
  methods: {
    async relocateEmployee(stationId) {
      this.$store.commit("SET_SELECTED_STATION", stationId);
      const selectedEmployeeId = this.selected_employee;
      const employeeToBeMoved = this.findEmployeeById(selectedEmployeeId);

      if (employeeToBeMoved) {
        const currentStation = this.findStationByEmployeeId(selectedEmployeeId);
        if (currentStation) {
          await this.removeEmployeeFromStation(
            currentStation,
            selectedEmployeeId
          );
        }
        const selectedStation = this.findStationById(stationId);
        if (selectedStation) {
          await this.moveEmployeeToStation(selectedStation, employeeToBeMoved);
        }
      }
    },
    async removeEmployeeFromStation(station, employeeId) {
      const updatedStation = {
        ...station,
        employees: station.employees.filter(
          (employee) => employee.id !== employeeId
        ),
      };

      try {
        // Retrieve the token from localStorage
        const token = localStorage.getItem("jwt");

        // Set up the headers with the Authorization token
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        await axios.put(
          `${process.env.VUE_APP_URL}/stations/${station.id}`,
          updatedStation,
          config
        );
        this.$store.dispatch("removeEmployeeFromStation", {
          stationId: station.id,
          employeeId,
        });
      } catch (error) {
        alert(error);
      }
    },

    async addEmployeeToStation(station, employee) {
      const updatedStation = {
        ...station,
        employees: [...station.employees, employee],
      };

      try {
        // Retrieve the token from localStorage
        const token = localStorage.getItem("jwt");

        // Set up the headers with the Authorization token
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        await axios.put(
          `${process.env.VUE_APP_URL}/stations/${station.id}`,
          updatedStation,
          config
        );
        this.$store.dispatch("updateStation", updatedStation);
      } catch (error) {
        alert(error);
      }
    },

    async updateEmployeeBreakTimestamp(employeeId, breakTimestamp) {
      // Retrieve the token from localStorage
      const token = localStorage.getItem("jwt");

      // Set up the headers with the Authorization token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        await axios.put(
          `${process.env.VUE_APP_URL}/employees/${employeeId}`,
          { break_timestamp: breakTimestamp },
          config
        );
      } catch (error) {
        alert(error);
      }
    },

    async moveEmployeeToStation(station, employee) {
      if (station.name === "PAUSE") {
        const breakTimestamp = this.$moment().format();
        await this.addEmployeeToStation(station, {
          ...employee,
          break_timestamp: breakTimestamp,
        });
        await this.updateEmployeeBreakTimestamp(employee.id, breakTimestamp);
      } else {
        await this.addEmployeeToStation(station, {
          ...employee,
          break_timestamp: "",
        });
        await this.updateEmployeeBreakTimestamp(employee.id, "");
      }
    },
    findEmployeeById(employeeId) {
      return this.stations
        .flatMap((station) => station.employees)
        .find((employee) => employee.id === employeeId);
    },

    findStationByEmployeeId(employeeId) {
      return this.stations.find((station) =>
        station.employees.some((employee) => employee.id === employeeId)
      );
    },
    findStationById(stationId) {
      return this.stations.find((station) => station.id === stationId);
    },
    openStationInfoDialog(stationId) {
      this.$store.commit("SET_SELECTED_STATION", stationId);
      this.$refs.stationInfoDialog.showModal();
    },
    openDialog(stationId) {
      this.$store.commit("SET_SELECTED_STATION", stationId);
      this.$refs.employeeDialog.showModal();
    },
    navigateToAddNewStationPage() {
      this.$router.push({ name: "AddNewStationPage" });
    },
  },
};
</script>
