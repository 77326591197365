<template>
  <b-container>
    <div v-if="filteredEmployee">
      <b-row class="mb-3 edit-candidate">
        <b-col offset-md="2" md="4">
          <label for="name">Name</label>
          <b-form-input
            id="name"
            class="mb-2"
            v-model="filteredEmployee.name"
          ></b-form-input>
          <label class="mt-3" for="employment_type">Chancen</label>
          <b-form-select
            id="employment_type"
            class="mb-2"
            v-model="filteredEmployee.employment_type"
            :options="options"
          ></b-form-select>
        </b-col>
        <b-col md="4">
          <label for="nachname">Nachname</label>
          <b-form-input
            id="nachname"
            class="mb-2"
            v-model="filteredEmployee.lastname"
          ></b-form-input>
          <label class="mt-3" for="bemerkungen">Bemerkungen</label>
          <textarea
            rows="10"
            :cols="noteFieldCols()"
            id="bemerkungen"
            class="mb-2"
            v-model="filteredEmployee.remark"
          ></textarea>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <div class="text-center">
            <b-button @click="redirectToHomePage()" variant="secondary"
              >Schlissen</b-button
            >
            <b-button
              class="ml-2"
              variant="danger"
              @click="openDeleteEmployeeDialog()"
              >Löschen</b-button
            >
            <b-button
              @click="updateEmployeeAndRedirect()"
              variant="primary ml-2"
              >Speichern</b-button
            >
          </div>
        </b-col>
      </b-row>
    </div>
    <!-- Modals -->
    <DeleteEmployeeDialog />
  </b-container>
</template>

<style lang="scss" scoped>
@media screen and (max-width: 485px) {
  .edit-candidate {
    margin-top: 200px;
  }
}
</style>

<script>
import axios from "axios";
import DeleteEmployeeDialog from "../components/DeleteEmployeeDialog.vue";
export default {
  name: "EmployeeEditPage",
  props: ["id"],
  components: {
    DeleteEmployeeDialog,
  },
  data() {
    return {
      options: [
        { value: "frueh", text: "Geringe" },
        { value: "spaet", text: "Normal" },
        { value: "schwanger", text: "Hoch" },
      ],
    };
  },
  computed: {
    employees() {
      return this.$store.state.employees;
    },
    filteredEmployee() {
      if (this.id) {
        return this.employees.find((employee) => employee.id === this.id);
      } else return null;
    },
  },
  methods: {
    updateEmployee() {
      if (this.filteredEmployee) {
        const updatedEmployeeData = {
          name: this.filteredEmployee.name,
          lastname: this.filteredEmployee.lastname,
          employment_type: this.filteredEmployee.employment_type,
          remark: this.filteredEmployee.remark,
        };
        // Retrieve the token from localStorage
        const token = localStorage.getItem("jwt");

        // Set up the headers
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        // Make the API call to update the employee on the server
        return axios.put(
          `${process.env.VUE_APP_URL}/employees/${this.id}`,
          updatedEmployeeData,
          config // pass the config object with headers
        );
      } else {
        return null;
      }
    },

    noteFieldCols() {
      return window.innerWidth < 485 ? 34 : 80;
    },
    redirectToHomePage() {
      this.$router.push({ name: "Home" });
    },
    updateEmployeeAndRedirect() {
      this.updateEmployee()
        .then(() => {
          this.redirectToHomePage();
        })
        .catch((error) => {
          console.error("Error updating employee:", error);
          // Handle error if necessary
        });
    },
    openDeleteEmployeeDialog() {
      this.$store.commit("SET_SELECTED_EMPLOYEE", this.id);
      this.$bvModal.show("delete-employee-confirmation");
    },
  },
};
</script>
