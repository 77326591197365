/**
 * Vuex store for managing state and mutations.
 *
 * @module store
 */

import Vue from "vue";
import Vuex from "vuex";
import set from "lodash/set";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  /**
   * Vuex store state.
   *
   * @property {Array} stations - List of stations.
   * @property {Array} employees - List of employees.
   * @property {string|null} selected_station - ID of the selected station.
   * @property {string|null} selected_employee - ID of the selected employee.
   * @property {boolean} userIsAuthed - Flag indicating whether the user is authenticated.
   * @property {string} search_input - Search input value.
   */
  state: {
    stations: [],
    employees: [],
    selected_station: null,
    selected_employee: null,
    userIsAuthed: localStorage.getItem("jwt"),
    userAuthedId: localStorage.getItem("authedId"),
    search_input: "",
  },

  /**
   * Vuex store mutations.
   */
  mutations: {
    /**
     * Set API data using lodash `set` function.
     *
     * @param {Object} state - Vuex store state.
     * @param {Object} payload - Data to set in state.
     * @param {string} payload.property - Property path to set.
     * @param {*} payload.value - Value to set.
     */
    SET_API_DATA(state, payload) {
      set(state, payload.property, payload.value);
    },

    /**
     * Set the selected station.
     *
     * @param {Object} state - Vuex store state.
     * @param {string|null} payload - ID of the selected station.
     */
    SET_SELECTED_STATION(state, payload) {
      state.selected_station = payload;
    },

    /**
     * Set the selected employee.
     *
     * @param {Object} state - Vuex store state.
     * @param {string|null} payload - ID of the selected employee.
     */
    SET_SELECTED_EMPLOYEE(state, payload) {
      state.selected_employee = payload;
    },

    /**
     * Set the user authentication status.
     *
     * @param {Object} state - Vuex store state.
     * @param {boolean} payload - Flag indicating whether the user is authenticated.
     */
    SET_USER_AUTHED(state, payload) {
      state.userIsAuthed = payload;
    },

    SET_USER_AUTHED_ID(state, payload) {
      state.userAuthedId = payload;
    },

    /**
     * Set the search input value.
     *
     * @param {Object} state - Vuex store state.
     * @param {string} payload - Search input value.
     */
    SET_SEARCH_INPUT(state, payload) {
      state.search_input = payload;
    },

    /**
     * Update a station in the stations list.
     *
     * @param {Object} state - Vuex store state.
     * @param {Object} updatedStation - Updated station object.
     */
    UPDATE_STATION(state, updatedStation) {
      const index = state.stations.findIndex(
        (station) => station.id === updatedStation.id
      );
      if (index !== -1) {
        const newStations = [...state.stations];
        newStations.splice(index, 1, updatedStation);
        state.stations = newStations; // Trigger reactivity
      }
    },

    /**
     * Remove an employee from a station.
     *
     * @param {Object} state - Vuex store state.
     * @param {Object} payload - Payload containing stationId and employeeId.
     * @param {string} payload.stationId - ID of the station.
     * @@param {string} payload.employeeId - ID of the employee to remove.
     */
    REMOVE_EMPLOYEE_FROM_STATION(state, { stationId, employeeId }) {
      const station = state.stations.find(
        (station) => station.id === stationId
      );
      if (station) {
        station.employees = station.employees.filter(
          (employee) => employee.id !== employeeId
        );
      }
    },
  },

  /**
   * Vuex store actions.
   */
  actions: {
    /**
     * Update a station in the store.
     *
     * @param {Object} context - Vuex action context.
     * @param {Object} station - Updated station object.
     */
    updateStation({ commit }, station) {
      commit("UPDATE_STATION", station);
    },

    /**
     * Remove an employee from a station.
     *
     * @param {Object} context - Vuex action context.
     * @param {Object} payload - Payload containing stationId and employeeId.
     * @param {string} payload.stationId - ID of the station.
     * @param {string} payload.employeeId - ID of the employee to remove.
     */
    removeEmployeeFromStation({ commit }, { stationId, employeeId }) {
      commit("REMOVE_EMPLOYEE_FROM_STATION", { stationId, employeeId });
    },

    /**
     * Update employees for a station.
     *
     * @param {Object} state - Vuex store state.
     * @param {Object} dispatch - Vuex action dispatcher.
     * @param {Object} payload - Payload containing employees and stationId.
     * @param {Array} payload.employees - List of employees to update.
     * @param {string} payload.stationId - ID of the station.
     */
    updateEmployeesToStation({ state, dispatch }, { employees, stationId }) {
      // Retrieve the token from localStorage
      const token = localStorage.getItem("jwt");

      // Set up the headers with the Authorization token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Promise array to keep track of all dispatch promises
      let dispatchPromises = [];

      employees.forEach((employee) => {
        // Store each dispatch promise in the array
        dispatchPromises.push(
          dispatch("addEmployeeToStation", {
            stationId: stationId,
            employee: employee,
          })
        );
      });

      // Once all employees have been added to the station
      Promise.all(dispatchPromises).then(() => {
        // Update the station data in the API
        const updatedStation = state.stations.find(
          (station) => station.id === stationId
        );

        // Use the token in the Authorization header
        axios
          .put(
            `${process.env.VUE_APP_URL}/stations/${stationId}`,
            updatedStation,
            config
          )
          .then((response) => {
            console.log("Updated station data:", response.data);
          })
          .catch((error) => {
            console.log("Error updating station data:", error);
          });
      });
    },

    /**
     * Add an employee to a station.
     *
     * @param {Object} commit - Vuex commit function.
     * @param {Object} state - Vuex store state.
     * @param {Object} payload - Payload containing stationId and employee.
     * @param {string} payload.stationId - ID of the station.
     * @param {Object} payload.employee - Employee object to add.
     */
    addEmployeeToStation({ commit, state }, { stationId, employee }) {
      const station = state.stations.find(
        (station) => station.id === stationId
      );

      if (!station) {
        console.log("Station not found");
        return;
      }

      const updatedStation = {
        ...station,
        employees: [...station.employees, employee],
      };

      commit("UPDATE_STATION", updatedStation);
    },

    /**
     * Create a new employee.
     *
     * @param {Object} context - Vuex action context.
     * @param {Object} payload - Payload containing employee data.
     * @returns {Promise} Promise representing the created employee.
     */
    createEmployee(context, payload) {
      if (!payload.name || !payload.lastname) {
        return Promise.reject(new Error("Name and lastname are required"));
      }

      // Retrieve the token from localStorage
      const token = localStorage.getItem("jwt");

      // Set up the headers with the Authorization token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      return axios
        .post(`${process.env.VUE_APP_URL}/employees`, payload, config)
        .then((response) => {
          // Handle the response, such as committing to a mutation or returning data
          context.commit("ADD_EMPLOYEE", response.data); // Example mutation
          return response.data;
        })
        .catch((error) => {
          console.error("Error creating new employee", error);
          throw error;
        });
    },
  },

  modules: {},
});
