<template>
  <b-container class="login-page" fluid>
    <b-row>
      <b-container>
        <b-col class="login-wrapper text-center" offset-md="3" md="6">
          <form>
            <!-- Email input -->
            <div class="form-outline mb-4">
              <input
                v-model="email"
                placeholder="E-mail"
                type="email"
                id="form2Example1"
                class="form-control"
              />
            </div>

            <!-- Password input -->
            <div class="form-outline">
              <VuePassword
                v-on:keyup.enter="logIn()"
                placeholder="Passwort"
                v-model="password"
              />
            </div>

            <!-- 2 column grid layout for inline styling -->
            <div class="row mb-4">
              <div class="col d-flex justify-content-center">
                <!-- Checkbox -->
                <div class="form-check">
                  <input
                    v-on:keyup.enter="logIn()"
                    class="form-check-input mt-2"
                    type="checkbox"
                    value=""
                    id="form2Example31"
                    checked
                  />
                  <label class="form-check-label" for="form2Example31">
                    Eingeloggt bleiben
                  </label>
                </div>
              </div>
            </div>

            <!-- Submit button -->
            <button
              @click="logIn()"
              type="button"
              class="btn btn-primary btn-block mb-4"
            >
              Jetzt Einloggen
            </button>
          </form>
        </b-col>
      </b-container>
    </b-row>
  </b-container>
</template>

<style lang="scss" scoped>
.login-page {
  background: #333;
  min-height: 100vh;
  padding-top: 100px;
  margin-top: 0px;
}
.login-wrapper {
  margin-top: 140px;
}
input {
  background: transparent;
  border: 1px solid white;
  border-radius: 20px;
  color: lightgrey;
  &:focus {
    background: transparent;
    color: white;
  }
}
.btn-primary {
  background: transparent;
  border: 1px solid white;
  border-radius: 20px;
  color: rgb(194, 191, 191);
}
::placeholder {
  color: rgb(194, 191, 191);
}
label {
  color: rgb(194, 191, 191);
}
.btn-secondary {
  background: transparent;
  border: 0px;
}
</style>

<script>
import axios from "axios";
import VuePassword from "vue-password";
export default {
  name: "Login",
  components: {
    VuePassword,
  },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    logIn() {
      axios
        .post(`${process.env.VUE_APP_URL}/auth/local`, {
          identifier: this.email,
          password: this.password,
        })
        .then((response) => {
          const { jwt, user } = response.data;
          window.localStorage.setItem("jwt", jwt);
          window.localStorage.setItem("authedId", user.id);
          window.localStorage.setItem("authedUsername", user.username);
          window.localStorage.setItem("authedEmail", user.email);
          this.$store.commit(
            "SET_USER_AUTHED",
            window.localStorage.getItem("jwt")
          );
          this.$store.commit(
            "SET_USER_AUTHED_ID",
            window.localStorage.getItem("authedId")
          );
          this.$router.push("/");
        })
        .catch((error) => {
          alert(error.response.data.message[0].messages[0].message);
        });
    },
  },
};
</script>
