<template>
  <b-modal
    id="delete-employee-confirmation"
    title="Bestätigung erforderlich"
    @ok="deleteEmployee()"
    ok-title="Ja"
    cancel-title="Nein!"
  >
    <p class="my-4">Mitarbeiter aus Datenbank löschen?</p>
  </b-modal>
</template>

<script>
import axios from "axios";
export default {
  name: "DeleteEmployeeDialog",
  computed: {
    selected_employee() {
      return this.$store.state.selected_employee;
    },
  },
  methods: {
    async deleteEmployee() {
      try {
        const token = localStorage.getItem("jwt");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        await axios.delete(
          `${process.env.VUE_APP_URL}/employees/${this.selected_employee}`,
          config
        );
        this.redirectToHomePage();
      } catch (error) {
        alert(error);
      }
    },

    redirectToHomePage() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
