<template>
  <b-modal id="station-info-dialog" hide-footer hide-header>
    <div v-if="filteredStation">
      <p class="border-bottom pb-2">
        <b> {{ filteredStation.name }} </b>
      </p>
      <p>
        <BIconPeopleFill class="mr-2" /> {{ filteredStation.employees.length }}
      </p>
      <p><BIconTelephoneFill class="mr-2" /> {{ filteredStation.tel }}</p>
      <p class="station-remark">
        <BIconChatLeftDotsFill class="mr-2" /> {{ filteredStation.remark }}
      </p>
      <b-button
        @click="openStationEditPage(filteredStation.id)"
        variant="primary mr-2"
        ><BIconPencilSquare
      /></b-button>
      <b-button @click="deleteStation()" variant="danger"
        ><BIconTrash
      /></b-button>
    </div>
  </b-modal>
</template>

<style lang="scss" scoped>
.station-remark {
  white-space: pre-line;
}
</style>
<script>
import { mapState } from "vuex";
export default {
  name: "StationInfoDialog",
  computed: {
    ...mapState(["stations", "selected_station"]),
    filteredStation() {
      return this.stations.find(
        (station) => station.id === this.selected_station
      );
    },
  },
  methods: {
    showModal() {
      this.$bvModal.show("station-info-dialog");
    },
    openStationEditPage(id) {
      this.$router.push({ name: "StationEditPage", params: { id: id } });
    },
    deleteStation() {
      this.$bvModal.show("delete-station-confirmation");
    },
  },
};
</script>
