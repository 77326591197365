<template>
  <div id="app">
    <TheHeader />
    <transition appear name="route" mode="out-in">
      <router-view class="router-view" />
    </transition>
  </div>
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

* {
  font-family: "Merriweather Sans", sans-serif;
}
.router-view {
  margin-top: 100px;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out !important;
}

.modal.fade.show .modal-dialog {
  transform: translate3d(0, 0, 0) !important;
}
.VuePassword__Input {
  background: transparent;
  color: white;
}
.VuePassword__Input--has-toggle {
  border-radius: 32px !important;
  padding: 6px !important;
  margin-bottom: 10px;
}
// Animation effect
.route-enter,
.route-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}
.route-enter-active,
.route-leave-active {
  transition: all 0.3s ease;
}
.route-enter-to,
.route-leave {
  opacity: 1;
  transform: translateY(0);
}
</style>

<script>
import axios from "axios";
import TheHeader from "@/components/TheHeader.vue";

const fetchData = (endpoint) => {
  const token = localStorage.getItem("jwt");

  const config = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
    },
    params: {
      _limit: -1,
      ...(endpoint === "stations" && { _sort: "id:ASC" }),
    },
  };

  return axios.get(`${process.env.VUE_APP_URL}/${endpoint}`, config);
};

export default {
  name: "App",
  components: {
    TheHeader,
  },
  mounted() {
    this.fetchAndCommitData(); // fetch data immediately on mount
  },
  watch: {
    "$route.name": {
      immediate: true,
      handler(newVal) {
        if (newVal === "Home") {
          this.fetchAndCommitData();
        } else {
          return;
        }
      },
    },
  },

  methods: {
    commitApiData(property, value) {
      this.$store.commit("SET_API_DATA", { property, value });
    },
    fetchAndCommitData() {
      const endpoints = ["stations", "employees"];

      axios.all(endpoints.map(fetchData)).then((responseArr) => {
        responseArr.forEach(({ data }, index) => {
          this.commitApiData(endpoints[index], data);
        });
      });
    },
  },
};
</script>
