<template>
  <div class="home">
    <b-container>
      <b-row>
        <!-- Welcome User Msg -->
        <b-col class="mb-0 pb-0 mt-3" lg="4" md="6">
          <h4>Hoi, {{ authedUsername }} 👋🏻</h4>
        </b-col>
      </b-row>

      <!-- Number of total employees attached to stations -->
      <b-row>
        <b-col lg="4" md="6">
          <h5>Kandidaten Anzahl: {{ totalEmployees }}</h5>
        </b-col>
      </b-row>

      <!-- Notes -->
      <b-row>
        <b-col lg="4" md="6">
          <textarea
            placeholder="Anmerkungen..."
            class="notes-field"
            rows="6"
            :cols="noteFieldCols"
            v-model="notesText"
            @blur="updateNotes()"
          ></textarea>
        </b-col>
      </b-row>

      <!-- Dotted divider line -->
      <b-row>
        <b-col md="12">
          <div class="divider"></div>
        </b-col>
      </b-row>

      <!-- Stations -->
      <StationsList />

      <!-- Modals -->
      <ClearAllEmployeesDialog />
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
.divider {
  border-bottom: 1px dotted lightgrey;
  margin-bottom: 30px;
  margin-top: 30px;
}
.newplan-btn {
  font-size: 12px !important;
}
@media screen and (max-width: 485px) {
  .home {
    margin-top: 140px;
  }
}
.notes-field {
  font-size: 10px;
  background: #e8e872;
  border: 0px;
  padding-left: 10px;
  padding-top: 10px;
}
</style>

<script>
import axios from "axios";
import StationsList from "@/components/StationsList.vue";
import ClearAllEmployeesDialog from "@/components/ClearAllEmployeesDialog.vue";

export default {
  name: "Home",
  components: {
    StationsList,
    ClearAllEmployeesDialog,
  },

  data() {
    return {
      notes: [],
    };
  },

  computed: {
    authedUsername() {
      return localStorage.getItem("authedUsername");
    },
    stations() {
      return this.$store.state.stations;
    },
    userAuthedId() {
      return this.$store.state.userAuthedId;
    },
    totalEmployees() {
      return this.stations.reduce((total, station) => {
        if (station.user.id == this.userAuthedId) {
          return total + station.employees.length;
        }
        return total;
      }, 0);
    },
    noteFieldCols() {
      return window.innerWidth < 485 ? 34 : 80;
    },
    notesText: {
      get() {
        return this.notes[0] ? this.notes[0].text : "";
      },
      set(value) {
        if (this.notes.length) {
          this.notes[0].text = value;
        } else {
          this.notes.push({ text: value });
        }
      },
    },
  },

  methods: {
    async fetchAllNotes() {
      try {
        const token = localStorage.getItem("jwt");

        const response = await axios.get(`${process.env.VUE_APP_URL}/notes`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.notes = response.data[0];
      } catch (error) {
        console.error(error);
      }
    },
    async updateNotes() {
      try {
        const token = localStorage.getItem("jwt");

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.post(
          `${process.env.VUE_APP_URL}/notes`,
          { text: this.notesText },
          config
        );

        if (response.data) {
          if (this.notes.length) {
            this.$set(this.notes, 0, response.data);
          } else {
            this.notes.push(response.data);
          }
        }
      } catch (error) {
        console.error("Error updating notes:", error);
      }
      this.fetchAllNotes();
    },
  },

  async mounted() {
    await this.fetchAllNotes();
  },
};
</script>
