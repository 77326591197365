<template>
  <b-modal
    id="clear-all-confirmation"
    title="Bestätigung erforderlich"
    @ok="clearAllEmployeesConfirmed"
    ok-title="Ja"
    cancel-title="Nein!"
  >
    <p class="my-4">Entfernen Sie alle Mitarbeiter?</p>
  </b-modal>
</template>

<script>
import axios from "axios";
export default {
  name: "ClearAllEmployeesDialog",
  computed: {
    stations() {
      return this.$store.state.stations;
    },
  },
  methods: {
    async clearAllEmployeesConfirmed() {
      try {
        for (const station of this.stations) {
          await this.clearEmployeesInStation(station);
          await this.updateStationOnServer(station);
          this.$store.dispatch("updateStation", station);
        }
        this.reloadWindow();
      } catch (error) {
        console.log(error);
      }
    },
    async clearEmployeesInStation(station) {
      station.employees = [];
    },
    async updateStationOnServer(station) {
      const token = localStorage.getItem("jwt");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      return axios.put(
        `${process.env.VUE_APP_URL}/stations/${station.id}`,
        {
          employees: station.employees,
        },
        config
      );
    },

    reloadWindow() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped></style>
