<template>
  <b-modal
    id="delete-station-confirmation"
    title="Bestätigung erforderlich"
    @ok="deleteStation()"
    ok-title="Ja"
    cancel-title="Nein!"
  >
    <p class="my-4">Station aus Datenbank löschen?</p>
  </b-modal>
</template>

<script>
import axios from "axios";
export default {
  name: "DeleteStationDialog",
  computed: {
    selected_station() {
      return this.$store.state.selected_station;
    },
  },
  methods: {
    async deleteStation() {
      try {
        const token = localStorage.getItem("jwt");
        await axios.delete(
          `${process.env.VUE_APP_URL}/stations/${this.selected_station}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        this.closeModals();
        location.reload();
      } catch (error) {
        alert(error);
      }
    },

    closeModals() {
      this.$bvModal.hide("delete-station-confirmation");
      this.$bvModal.hide("station-info-dialog");
    },
  },
};
</script>

<style lang="scss" scoped></style>
