<template>
  <div>
    <div
      v-for="employee in stationemployees"
      :key="employee.id"
      :class="employeeWrapperClass(employee)"
      @click.stop="selectEmployee(employee.id)"
    >
      <ul class="list-inline employee-info">
        <li class="list-inline-item">
          <p>
            {{ employee.lastname }} {{ employee.name }}
            <i v-if="employee.break_timestamp" class="employee-timestamp">
              {{ getEmployeeTimestamp(employee.break_timestamp) }}
            </i>
          </p>
        </li>
        <li
          @click="openEmployeeRemarksDialog()"
          v-if="employee.remark"
          class="list-inline-item employee-remark"
        >
          <BIconBellFill />
        </li>
        <li class="list-inline-item">
          <div
            class="remove-icon"
            @click.stop="removeEmployee(stationId, employee.id)"
          >
            <BIconX />
          </div>
        </li>
        <li class="list-inline-item ml-5">
          <div
            class="edit-icon"
            @click.stop="openEmployeeEditPage(employee.id)"
          >
            <BIconPencil />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.employee-wrapper {
  cursor: pointer;
  margin-left: 5px;
  p {
    margin-top: 2px;
    margin-bottom: 5px;
  }
}
.employee-wrapper-spaet {
  background-color: #50c878;
  color: black;
  border-radius: 5px;
}
.employee-wrapper-frueh {
  background-color: #62b6e7;
  border-radius: 5px;
}
.employee-wrapper-schwanger {
  background-color: #dc3545;
  color: rgb(225, 225, 225);
  border-radius: 5px;
}

.selected-employee {
  transition: 0.3s;
  background-color: #555 !important;
  color: white;
}
.employee-info {
  position: relative;
  margin-bottom: 3px;
  margin-left: 20px;
  .employee-remark {
    font-size: 12px;
    position: absolute;
    right: 54px;
    bottom: 5px;
  }
  .remove-icon {
    position: absolute;
    right: 7px;
    bottom: 2px;
  }
  .edit-icon {
    position: absolute;
    right: 35px;
    bottom: 3.5px;
    font-size: 15px;
    svg {
      font-size: 10px;
    }
  }
}
.employee-timestamp {
  font-size: 10px;
  margin-left: 10px;
}

.employee-late {
  background-color: red;
  color: white;
}
</style>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "EmployeesList",
  props: ["stationemployees", "stationId"],
  computed: {
    ...mapState(["stations", "selected_employee"]),
  },
  mounted() {
    this.windowClickListener = this.deselectEmployee;
    window.addEventListener("click", this.windowClickListener);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.windowClickListener);
  },
  methods: {
    employeeWrapperClass(employee) {
      return [
        "employee-wrapper",
        this.getEmployeeWrapperClass(employee.employment_type),
        { "selected-employee": this.selected_employee === employee.id },
        { "employee-late": this.isEmployeeLate(employee.break_timestamp) },
      ];
    },
    getEmployeeWrapperClass(employment_type) {
      const classMap = {
        spaet: "employee-wrapper-spaet",
        frueh: "employee-wrapper-frueh",
        schwanger: "employee-wrapper-schwanger",
      };
      return classMap[employment_type] || "";
    },
    selectEmployee(id) {
      this.$store.commit("SET_SELECTED_EMPLOYEE", id);
    },
    deselectEmployee() {
      this.$store.commit("SET_SELECTED_EMPLOYEE", null);
    },
    openEmployeeRemarksDialog() {
      this.$bvModal.show("employeeRemarksDialog");
    },
    openEmployeeEditPage(id) {
      this.$router.push({ name: "EmployeeEditPage", params: { id: id } });
    },
    removeEmployee(stationId, employeeId) {
      const station = this.findStationById(stationId);
      if (station) {
        const updatedStation = this.getUpdatedStationWithEmployeeRemoved(
          station,
          employeeId
        );
        this.updateStationOnServer(stationId, updatedStation)
          .then(() => {
            this.dispatchRemoveEmployeeFromStation(stationId, employeeId);
          })
          .catch((error) => {
            alert(error);
          });
      }
    },
    findStationById(stationId) {
      return this.stations.find((station) => station.id === stationId);
    },
    getUpdatedStationWithEmployeeRemoved(station, employeeId) {
      return {
        ...station,
        employees: station.employees.filter(
          (employee) => employee.id !== employeeId
        ),
      };
    },
    updateStationOnServer(stationId, updatedStation) {
      const token = localStorage.getItem("jwt");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      return axios.put(
        `${process.env.VUE_APP_URL}/stations/${stationId}`,
        updatedStation,
        config
      );
    },

    dispatchRemoveEmployeeFromStation(stationId, employeeId) {
      this.$store.dispatch("removeEmployeeFromStation", {
        stationId,
        employeeId,
      });
    },
    isEmployeeLate(breakTimestamp) {
      if (breakTimestamp) {
        const diffMinutes = this.$moment().diff(
          this.$moment(breakTimestamp),
          "minutes"
        );
        return diffMinutes > 15;
      }
      return false;
    },
    getEmployeeTimestamp(breakTimestamp) {
      return this.$moment(breakTimestamp)
        .startOf("minutes")
        .fromNow();
    },
  },
};
</script>
