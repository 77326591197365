<template>
  <b-container>
    <div v-if="filteredStation">
      <b-row class="mb-3 edit-company">
        <b-col offset-md="2" md="4">
          <label for="name">Name</label>
          <b-form-input
            id="name"
            class="mb-2"
            v-model="filteredStation.name"
          ></b-form-input>
        </b-col>
        <b-col md="4">
          <label for="tel">Tel</label>
          <b-form-input
            id="tel"
            class="mb-2"
            v-model="filteredStation.tel"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col offset-md="2" md="4">
          <label class="mb-2" for="bemerkungen">Bemerkungen</label>
          <textarea
            rows="10"
            :cols="noteFieldCols()"
            id="bemerkungen"
            class="mb-3"
            v-model="filteredStation.remark"
          ></textarea>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <div class="text-center">
            <b-button @click="redirectToHomePage()" variant="secondary"
              >Schlissen</b-button
            >
            <b-button @click="updateStationAndRedirect()" variant="primary ml-2"
              >Speichern</b-button
            >
          </div>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<style lang="scss" scoped>
@media screen and (max-width: 485px) {
  .edit-company {
    margin-top: 200px;
  }
}
</style>

<script>
import axios from "axios";
export default {
  name: "StationEditPage",
  props: ["id"],
  computed: {
    stations() {
      return this.$store.state.stations;
    },
    filteredStation() {
      if (this.id) {
        return this.stations.find((station) => station.id === this.id);
      } else return null;
    },
  },
  methods: {
    updateStation() {
      if (this.filteredStation) {
        const updatedStationData = {
          name: this.filteredStation.name,
          tel: this.filteredStation.tel,
          remark: this.filteredStation.remark,
        };

        const token = localStorage.getItem("jwt");

        if (!token) {
          console.error("No token found");
          return Promise.reject("No token found");
        }

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        return axios.put(
          `${process.env.VUE_APP_URL}/stations/${this.id}`,
          updatedStationData,
          config
        );
      } else {
        return null;
      }
    },

    redirectToHomePage() {
      this.$router.push({ name: "Home" });
    },
    updateStationAndRedirect() {
      this.updateStation()
        .then(() => {
          this.redirectToHomePage();
        })
        .catch((error) => {
          console.error("Error updating station:", error);
        });
    },
    noteFieldCols() {
      return window.innerWidth < 485 ? 34 : 80;
    },
  },
};
</script>
