<template>
  <b-container>
    <b-row class="mb-3 add-new-company">
      <b-col offset-md="2" md="4">
        <label for="name">Name der Firma</label>
        <b-form-input id="name" class="mb-2" v-model="name"></b-form-input>
        <label class="mt-3" for="Tel">Tel</label>
        <b-form-input id="Tel" class="mb-2" v-model="tel"></b-form-input>
      </b-col>
      <b-col md="4">
        <label for="bemerkungen">Bemerkungen</label>
        <textarea
          rows="8"
          :cols="noteFieldCols()"
          id="bemerkungen"
          class="mb-2"
          v-model="remark"
        ></textarea>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <div class="text-center">
          <b-button @click="redirectToHomePage()" variant="secondary"
            >Schlissen</b-button
          >
          <b-button @click="addNewStationAndRedirect()" variant="primary ml-2"
            >Speichern</b-button
          >
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<style lang="scss" scoped>
@media screen and (max-width: 485px) {
  .add-new-company {
    margin-top: 200px;
  }
}
</style>

<script>
import axios from "axios";
export default {
  name: "AddNewStationPage",
  data() {
    return {
      name: "",
      tel: "",
      remark: "",
    };
  },
  computed: {
    stations() {
      return this.$store.state.stations;
    },
    userAuthedId() {
      return this.$store.state.userAuthedId;
    },
  },
  methods: {
    addNewStation() {
      const updatedStationData = {
        name: this.name,
        tel: this.tel,
        remark: this.remark,
        user: {
          id: this.userAuthedId,
        },
      };
      const token = localStorage.getItem("jwt");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      return axios.post(
        `${process.env.VUE_APP_URL}/stations`,
        updatedStationData,
        config
      );
    },
    noteFieldCols() {
      return window.innerWidth < 485 ? 34 : 80;
    },
    redirectToHomePage() {
      this.$router.push({ name: "Home" });
    },
    addNewStationAndRedirect() {
      this.addNewStation()
        .then(() => {
          this.redirectToHomePage();
        })
        .catch((error) => {
          alert("Fülle das Formular aus", error);
          // Handle error if necessary
        });
    },
  },
};
</script>
